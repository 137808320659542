<template>
  <el-card class="main-card">
    <template #header>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">订单管理</el-breadcrumb-item>
        <el-breadcrumb-item>订单详情</el-breadcrumb-item>
      </el-breadcrumb>
    </template>

    <div class="toolbar">
      <el-button size="small" type="info" class="el-icon-back" @click="back()">返回</el-button>
    </div>
    
    <el-form :model="forms" ref="forms" :label-width="110">
      <el-row :gutter="10">
        <el-col :xs="24" :sm="16" :md="16" :lg="16" :xl="16">
          <el-form-item label="款式名称" prop="detailName" style="width: 95%">
            <el-input v-model="forms.detailName" autocomplete="off">
            </el-input>
          </el-form-item>
        </el-col>

      </el-row>
    </el-form>

  </el-card>
</template>
<script>
export default {
  name: "detail",
  components: {},
  data() {
    return {
      forms: {},
    };
  },
  methods: {
    loadData() { },
    back() {
      this.$router.go(-1);
    },
    
  },
  mounted() { },
  created() {
  },
};
</script>
<style scoped>
</style>